<template>
  <div class="vascularAccessList">
    <div class="info-table">
      <el-table
        :data="tableData"
        v-loading="tableLoading"
        border
        size="small"
        style="width: 100%"
        max-height="300"
      >
        <el-table-column
          prop="tonglu_type"
          label="通路类型"
          width="140"
        />
        <el-table-column prop="tonglu_type" label="通路部位" width="140" />
        <el-table-column prop="intervene_type" label="介入方式" width="140" />
        <el-table-column prop="make_time" label="建立日期" width="160" />
        <el-table-column prop="enable_time" label="启用日期" width="160" />
        <el-table-column prop="doctor.name" label="手术者" width="120" />
        <el-table-column prop="department" label="科室" width="140" />
        <el-table-column label="使用状态" width="120">
          <template #default="scope">
            <el-tag v-if="scope.row.enable === 1">启用</el-tag>
            <el-tag v-else type="danger">停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="disable_time" label="停用时间" width="160" />
        <el-table-column prop="disable_reason" label="停用原因" width="140" />
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click.prevent.stop="dialysisPlan(scope.row)"
            >
              <i v-if="scope.row.enable === 1" class="txIons stop"></i>
              <i v-else class="txIons enable"></i>
            </el-button>
            <el-button
              v-if="scope.row.enable === 1"
              type="text"
              size="small"
              @click.prevent.stop="modDialysis(scope.row)"
            >
              <i class="txIons modify"></i>
            </el-button>
            <el-button
              type="text"
              size="small"
              @click.prevent.stop="delDialysis(scope.row)"
            >
              <i class="txIons del"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-box">
      <el-pagination
        :currentPage="pageData.currentPage"
        :page-size="pageData.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.totalSum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <BaseDialog
      title="停用"
      :isshow="visible"
      @handleShow="handleShow"
      width="600px"
      top="20%"
    >
      <div class="tongluBox">
        <el-form
          :model="ruleForm"
          label-width="80px"
          label-position="right"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="停用原因">
            <el-input
              v-model="ruleForm.disable_reason"
              maxlength="1000"
              :rows="5"
              placeholder="请输入停用原因"
              show-word-limit
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="accessItem"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
    <modVascularAccess
      v-if="isModVascularAccess"
      ref="modVascularAccessRef"
      @refreshDataList="getDataList()"
    ></modVascularAccess>
  </div>
</template>
<script>
import { ref, reactive, toRefs, nextTick, onMounted } from 'vue'
import service from '@/utils/request'
import { useRoute } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
import modVascularAccess from './modVascularAccess.vue'
export default {
  name: 'vascularAccessList',
  components: {
    modVascularAccess,
    BaseDialog,
  },
  setup() {
    const modVascularAccessRef = ref(null)
    const state = reactive({
      searchData: {},
      ruleForm: {},
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      isModVascularAccess: false,
      tableLoading: false,
      visible: false,
      saveLoading: false,
    })

    const route = useRoute()
    onMounted(() => {
      state.searchData.patient_id = route.params.id
      getDataList()
    })
    const getDataList = async () => {
      state.searchData.current = state.pageData.currentPage
      state.searchData.size = state.pageData.pageSize
      state.tableLoading = true
      let res = await service.post(
        '/api/patient_tonglu/get_tonglu_list',
        state.searchData
      )
      state.tableLoading = false
      if (res.code === 0) {
        state.tableData = res.data.records
        state.pageData.totalSum = res.data.total
      }
    }

    const addAccessList = () => {
      state.isModVascularAccess = true
      nextTick(() => {
        modVascularAccessRef.value.initData()
      })
    }
    const modDialysis = (row) => {
      state.isModVascularAccess = true
      nextTick(() => {
        modVascularAccessRef.value.initData(row)
      })
    }

    const handleSizeChange = (val) => {
      state.pageData.pageSize = val
      getDataList()
    }

    const handleCurrentChange = (val) => {
      state.pageData.currentPage = val
      getDataList()
    }

    const dialysisPlan = (item) => {
      let contText = `您确认要${item.enable === 1 ? '停用' : '启用'}吗？`
      ElMessageBox.confirm(contText, '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          if (item.enable === 1) {
            state.ruleForm.tonglu_id = item.id
            state.visible = true
          } else {
            let res = await service.post('/api/patient_tonglu/enable_tonglu', {
              tonglu_id: item.id,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
            }
          }
        })
        .catch(() => {})
    }
    const delDialysis = (item) => {
      ElMessageBox.confirm('您确认要删除吗?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          let res = await service.post('/api/patient_tonglu/delete_tonglu', {
            tonglu_id: item.id,
          })
          if (res.code === 0) {
            ElMessage.success(res.msg)
            getDataList()
          }
        })
        .catch(() => {})
    }

    const accessItem = async () => {
      let res = await service.post(
        '/api/patient_tonglu/disable_tonglu',
        state.ruleForm
      )
      if (res.code === 0) {
        ElMessage.success(res.msg)
        state.visible = false
        getDataList()
      }
    }

    const handleShow = (val) => {
      state.visible = val
    }

    return {
      ...toRefs(state),
      modVascularAccessRef,
      addAccessList,
      handleSizeChange,
      handleCurrentChange,
      getDataList,
      dialysisPlan,
      delDialysis,
      handleShow,
      accessItem,
      modDialysis,
    }
  },
}
</script>

<style scoped lang="scss">
.vascularAccessList {
  position: relative;
}
.list-but {
}
</style>
