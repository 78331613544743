<template>
  <div class="scheme">
    <BaseDialog
      title="血管通路"
      :isshow="visible"
      @handleShow="handleShow"
      width="912px"
      height="auto"
    >
      <div class="schemeBox mar20">
        <div class="userItem">
          <el-form
            ref="ruleFormsRef"
            :model="ruleForm"
            :rules="Rule.VASCULARASSESS"
            label-width="104px"
            label-position="right"
            :inline="true"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="通路类型：" prop="dict_tonglu_type">
              <el-select
                v-model="ruleForm.dict_tonglu_type"
                @change="accessChe"
                placeholder="请选择通路类型"
              >
                <el-option
                  v-for="item in dictList[110000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="建立部位：" prop="dict_tonglu_position">
              <el-select
                v-model="ruleForm.dict_tonglu_position"
                placeholder="请选择建立部位"
              >
                <el-option
                  v-for="item in dictList.dict_tonglu_position"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="介入方式："
              v-if="
                dictList.dict_intervene_typeData &&
                dictList.dict_intervene_typeData.length > 0
              "
              prop="dict_intervene_type"
            >
              <el-radio-group v-model="ruleForm.dict_intervene_type">
                <el-radio
                  v-for="item in dictList.dict_intervene_typeData"
                  :key="item.id"
                  :label="item.code"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="建立日期：">
              <el-date-picker
                v-model="ruleForm.make_time"
                type="datetime"
                value-format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择建立日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="启用日期：">
              <el-date-picker
                v-model="ruleForm.enable_time"
                type="datetime"
                value-format="YYYY-MM-DD HH:mm:ss"
                placeholder="请选择启用日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手术者：">
              <el-select
                v-model="ruleForm.doctor_id"
                placeholder="请选择手术者"
              >
                <el-option
                  v-for="item in dictList.userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科室：">
              <el-select
                v-model="ruleForm.department_id"
                placeholder="请选择科室"
              >
                <el-option
                  v-for="item in dictList[156000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用状态：">
              <el-select v-model="ruleForm.enable" placeholder="请选择">
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="视图位置：">
              <el-select
                v-model="ruleForm.dict_img"
                placeholder="请选择视图位置"
              >
                <el-option
                  v-for="item in dictList[901000000]"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="userItem">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            label-width="104px"
            label-position="right"
            :inline="true"
            class="demo-ruleForms col-333"
          >
            <el-form-item label="备注">
              <el-input
                v-model="ruleForm.remark"
                maxlength="1000"
                :rows="5"
                placeholder="请输入备注"
                show-word-limit
                type="textarea"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveAccess"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { gitDictList, ruleTimeYear } from '@/utils/tool.js'
import Rule from '@/utils/rule'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
  },
  emits: ['refreshDataList'],
  setup(props, ctx) {
    const state = reactive({
      dataForm: {},
      ruleForm: {},
      dictList: {},
      visible: false,
      Rule: Rule,
      ruleFormsRef: null,
      saveLoading: false,
    })

    const initData = async (item) => {
      state.visible = true
      formInit()
      await getDict()
      if (item && item.id) {
        state.ruleForm = item
        getPosition(item.dict_tonglu_type)
        getIntervene(item.dict_tonglu_type)
      }
      getUserList()
    }

    const getDict = async () => {
      state.dictList = {}
      state.dictList = await gitDictList([
        '110000000',
        '156000000',
        '901000000',
      ])
    }
    const getUserList = async () => {
      // state.dictList.userList = [JSON.parse(localStorage.getItem('ms_userData'))]
      let res = await service.post('/api/user/list', {
        role_id: '1',
      })
      if (res.code === 0) {
        state.dictList.userList = res.data.records
      }
    }

    const formInit = () => {
      // state.ruleForm = {}
      state.ruleForm = {
        enable: 1,
        make_time: ruleTimeYear(new Date()),
        enable_time: ruleTimeYear(new Date()),
      }
    }

    const accessChe = (val) => {
      state.ruleForm.dict_tonglu_position = ''
      getPosition(val)
      getIntervene(val)
    }
    const getPosition = async (val) => {
      let res = await service.post(
        '/api/patient_tonglu/get_tonglu_position_config_by_tonglu_type',
        {
          dict_tonglu_type: val,
        }
      )
      if (res.code === 0) {
        state.dictList.dict_tonglu_position = res.data.tonglu_position_config
      }
    }
    const getIntervene = async (val) => {
      let res = await service.post(
        '/api/patient_tonglu/get_intervene_type_config_by_tonglu_type',
        {
          dict_tonglu_type: val,
        }
      )
      if (res.code === 0) {
        state.dictList.dict_intervene_typeData = res.data.intervene_type_config
        if (
          res.data.intervene_type_config &&
          res.data.intervene_type_config.length > 0
        ) {
          state.ruleForm.dict_intervene_type =
            res.data.intervene_type_config[0].code
        }
      }
    }

    const route = useRoute()
    const saveAccess = () => {
      state.ruleFormsRef.validate(async (valid) => {
        if (valid) {
          state.ruleForm.patient_id = route.params.id
          let url = ''
          if (state.ruleForm.id) {
            state.ruleForm.tonglu_id = state.ruleForm.id
            url = '/api/patient_tonglu/edit_tonglu'
          } else {
            url = '/api/patient_tonglu/create_tonglu'
          }
          let res = await service.post(url, state.ruleForm)
          if (res.code === 0) {
            ElMessage.success(res.msg)
            state.visible = false
            ctx.emit('refreshDataList')
          }
        }
      })
    }

    const handleShow = (val) => {
      state.visible = val
    }

    return {
      ...toRefs(state),
      initData,
      handleShow,
      accessChe,
      saveAccess,
    }
  },
}
</script>

<style scoped lang="scss">
.templateBut {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 10px;
}
.schemeBox {
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
  }
  .demo-ruleForms .el-form-item {
    width: 100%;
    margin-right: 0;
  }
  .el-form-item {
    width: 45%;
    margin-right: 10%;
  }
  .el-form-item:nth-child(even) {
    margin-right: 0;
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
</style>
